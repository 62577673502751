import { Component } from "react";
import styled from "styled-components";
// Utils
import { Font, rem, responsive } from "../../utils/style";
import ReviewPolicyModal from "../reviews/ReviewPolicyModal";
// Components
import Row from "../Row";
import Text from "../Text";

const HeaderSection = styled.section`
  margin: 74px 0;

  ${responsive.md`
    margin: 80px 0;
  `}
`;

const HeaderRow = styled.div``;

const HeaderColumn = styled.div.attrs({
  className: "col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 text-center",
})``;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    ${Font.circular}
    font-size: ${rem(30)};
    line-height: ${rem(36)};
    letter-spacing: -0.4px;
    text-align: center;
    margin-bottom: 16px;

    ${responsive.md`
      font-size: ${rem(48)};
      line-height: ${rem(54)};
      letter-spacing: -1.4px;
      margin-bottom: 24px;
    `}
  }

  p {
    ${Font.dutch}
    font-size: ${rem(16)};
    line-height: ${rem(26)};
    margin-bottom: 0;

    ${responsive.md`
      font-size: ${rem(18)};
      line-height: ${rem(28)};
    `}
  }
`;

// const Headers

const LearnMoreButton = styled.a.attrs({
  className: "-underline",
})`
  border-width: 1px !important;
`;

export default class ReviewsHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  render() {
    const { heroTitle, heroSubhead } = this.props;

    return (
      <HeaderSection>
        <HeaderRow>
          <HeaderColumn>
            <Header>
              <h1>{heroTitle}</h1>
              <p>
                {heroSubhead}{" "}
                <LearnMoreButton onClick={this.openModal}>
                  <Text id="general.learn-more" defaultMessage="Learn More" />
                </LearnMoreButton>
                .
              </p>
            </Header>
          </HeaderColumn>
        </HeaderRow>

        <ReviewPolicyModal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
        />
      </HeaderSection>
    );
  }
}
